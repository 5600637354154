<template>
  <div class="container-projects">
    <div class="f-c-19 f-s-22 font-bold f-f-raleway l-h-26 title">
      <span>{{ data.title }} <label v-if="data.title && data.subtitle"> - </label></span>
      <span class="f-c-1">{{ data.subtitle }}</span>
    </div>

    <ul ref="container" class="flex-row flex-wrap">
      <template v-for="(project, i) in data.list">
        <li 
          :key="project.id" 
          :style="{width: `${boxSize.width}px`, marginRight: (i+1)%3 === 0 ? 0 : '30px'}"
          @click="onProject(project)">
          <div class="image-box position-relative">
            <div 
              class="lazy"
              :data-src="project.image"></div>
          </div>

          <div class="f-s-16 f-c-1 font-bold f-f-neue l-h-19">{{ project.name }}</div>
        </li>
      </template>
    </ul>
  </div>
</template>
<script>
export default {
  name: "Projects",
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      boxSize: {
        width: 0,
      },
      timer: null,
      listener: null,
    }
  },
  created() {
    this.timer = setTimeout(() => { this.setBoxSize(); }, 100);
    this.listener = window.addEventListener('resize', this.setBoxSize);
  },
  destroyed() {
    clearTimeout(this.timer);
    removeEventListener('resize', this.listener);
  },
  methods: {
    setBoxSize() {
      const container = this.$refs.container.clientWidth;
      this.boxSize.width = (container - 61)/3;
    },
    onProject(project) {
      this.$router.push({path: '/project-detail', query: {id: project.name}});
    }
  }
};
</script>
<style lang="scss" scoped>
.container-projects {
  .title {
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(112, 112, 112, 0.5);
  }

  ul {
    margin-top: 2rem;

    /* li { width: 31.7%; margin-bottom: 5.5rem; } */
    li { margin-bottom: 55px; margin-right: 30px; }
  }

  .image-box {
    display: block;
    margin: 0 auto;
    width: 100%;
    overflow: hidden;
    height: 30.4rem; 
    margin-bottom: 2.16rem;

    div {
      width: 100%;
      height: 100%;
      transform: scale(1);
      transition: all 1s ease 0s;
      background-size: cover;
      background-position: center;
      background-image: url('../../../assets/images/others/img-default.png');
    }
  }
  .image-box:hover div {
    transform: scale(1.2);
    transition: all 1s ease 0s;
  }
}
</style>