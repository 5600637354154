<template>
  <div class="flex-row project-gallery">
    <div 
      :ref="data.name"
      class="flex-1 left position-relative lazy"
      :data-src="currentImage">
      <ul class="position-absolute flex-row justify-content-center align-items-center">
        <template v-for="(item, i) in data.images">
          <li 
            :key="i"
            class="lazy"
            :data-src="item"
            :style="{opacity: currentImage === item ? 1 : 0.6}"
            @click="switchImage(item)"></li>
        </template>
      </ul>
      <!-- <div v-if="showShadow" class="layer-loading position-absolute flex-row align-items-center justify-content-center">
        <i class="iconfont iconLoading f-c-0 f-s-40"></i>
      </div> -->
    </div>

    <div class="right flex-row align-items-center">
      <div class="content">
        <p class="f-s-30 f-f-raleway font-bold l-h-35 f-c-1">{{ data.name }}</p>
        <gap :height="7" />
        <p class="l-h-16 location"><i class="iconfont iconiconset0391"></i><span class="f-s-14">{{ data.location }}</span></p>
        <gap :height="39" />
        <div class="f-s-16 l-h-30 f-c-1 f-f-arial desc">{{ data.desc }}</div>
        <gap :height="24" />
        <button class="f-s-14 f-c-0" @click="onProject()">
          <span>{{ $t('message.urban_project_find_out_more') }}</span> <i class="iconfont iconjiantou1"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProjectGallary",
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      currentImage: this.data.images[0],
      showShadow: false,
      // timer: null,
    }
  },
  /* destroyed() {
    clearTimeout(this.timer);
  }, */
  methods: {
    switchImage(item) {
      this.currentImage = item;
      // this.showShadow = true;
      this.$refs[this.data.name].style.backgroundImage = `url('${item}')`;
      // this.timer = setTimeout(() => { this.showShadow = false; }, 300);
    },
    onProject() {
      this.$router.push({path: '/project-detail', query: {id: this.data.id}});
    }
  }
}
</script>
<style lang="scss" scoped>
  .project-gallery {
    height: 44.8rem;
    margin-top: 3rem;

    .left { 
      background-size: cover;
      background-position: center;
      background-color: #dde2e6;

      .layer-loading {
        width: 100%;
        height: 100%; 
        background-color: rgba(0,0,0,0.3); 
        z-index: 10;
        i {
          -webkit-animation:spin 3s linear infinite;
          -moz-animation:spin 3s linear infinite;
          animation:spin 3s linear infinite;
        }
      }
      @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
      @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
      @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

      ul {
        bottom: 0;
        left: 0; 
        right: 0; 
        height: 7rem; 
        z-index: 20;
        background-color: rgba(226, 238, 255, .37);
        backdrop-filter: blur(2px);
        -webkit-backdrop-filter: blur(2px);
        li { 
          width: 8rem; 
          height: 4.9rem; 
          margin-right: 2rem;
          background-size: cover;
          background-position: center;
          outline: 1px solid #fff;
          outline-offset: -1px;
          opacity: .6;
          cursor: pointer;
          background-image: url('../../../assets/images/others/img-default.png');
        }
      }
    }
    .right { 
      width: 39.75%; 
      background-color: #E2EEFF;
      .content {
        padding: 0 4rem 0 2.4rem;

        text-align: right;
        .location { 
          color: rgba(0, 0, 0, .5);
          i { padding-right: 1rem; font-size: 1.3rem; }
        }
        .desc { 
          letter-spacing: 0.08px;
          overflow: hidden;
          text-overflow: ellipsis;
          display:-webkit-box;
          -webkit-line-clamp: 6;
          -webkit-box-orient:vertical;
        }
        button { border-radius: 4px; border: 0; background-color: #4A91F2; width: 15.7rem; height: 4.3rem; line-height: 4.3rem;}
        button i { padding-left: 1.5rem; }
      }
    }
  }
</style>