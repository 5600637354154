export default {
  otherProjects: {
    title: '', 
    subtitle: '开发的项目',
    list: [
      {id: 'The ALPS', name: '阿尔卑斯', image: require('@/assets/images/property-dev/alps/1.jpg') },
      {id: 'Poiz', name: '博雅居', image: require('@/assets/images/property-dev/poiz/2.jpg') },
      {id: 'One Canberra', name: 'One Canberra', image: require('@/assets/images/property-dev/one-canberra/4.jpg') },
      {id: 'Canberra Residences', name: 'Canberra Residences', image: require('@/assets/images/property-dev/canberra-residences/3.jpg') },
      {id: 'The Canopy', name: 'The Canopy', image: require('@/assets/images/property-dev/one-canopy/2.jpg') },
    ]
  },
  projectMag: {
    title: '',
    subtitle: '管理的项目',
    list: [
      {id: 'The Nautical', name: 'The Nautical', image: require('@/assets/images/property-dev/nautical/1.jpg')},
      {id: 'Forestville', name: 'Forestville', image: require('@/assets/images/property-dev/forestville/1.jpg')},
      {id: 'Sea Horizon', name: '海上地平线', image: require('@/assets/images/property-dev/sea-horizon/1.jpg')},
      {id: 'Queens Peak', name: '女皇碧苑', image: require('@/assets/images/property-dev/queens-peak/1.jpg')},
      {id: 'NorthWave', name: 'NorthWave', image: require('@/assets/images/property-dev/north-wave/1.jpg')},
    ]
  },
  onSalesProjects: [
    {
      title: '热销项目',
      subtitle: '新加坡',
      projects: [
        {
          id: 'Sceneca',
          name: '景乐苑及景乐坊', 
          location: '新加坡', 
          desc: `
          景乐苑及景乐坊综合发展项目总占地面积8,880平方米， 这个拥有99年地契的发展项目由两座14/15层的高楼组成，合计共有268个公寓单位，单位类型包括一卧室、一卧室+书房、两卧室、两卧室+书房、三卧室、四卧室，以及四间顶层豪华四卧室公寓。该项目休闲设施包括俱乐部会所、健身房、50米的游泳池、水上步道、家庭休息室、休憩亭、儿童游乐场、儿童嬉水池、秋千花园、多功能大厅、烧烤亭等。
          
          该项目位于新加坡东部的丹那美拉克基连路，衔接丹那美拉地铁站，毗邻巴士转换站、轻轨列车站等主要交通枢纽；对于驾车人士来说，只需几分钟车程，便可以从景乐苑开往泛岛快速公路（PIE）、淡滨尼快速公路（TPE）和加冷—巴耶利峇快速公路（KPE）等高速公路。地面层建有景乐苑商场，包含一家一千平方米的智能超市及两个社区活动广场；这里还拥有各种便利设施，如超市、零售店、娱乐中心、咖啡座、餐厅、银行。能一站式满足衣、食、住、行、游、购、娱的多元化生活方式，居民坐享工作与生活的便利，投资自住皆宜。
          `,
          images: [
            require("@/assets/images/property-dev/sceneca/sceneca_1.png"),
            require("@/assets/images/property-dev/sceneca/sceneca_2.png"),
            require("@/assets/images/property-dev/sceneca/sceneca_3.png"),
            require("@/assets/images/property-dev/sceneca/sceneca_4.png"),
            require("@/assets/images/property-dev/sceneca/sceneca_5.png"),
            require("@/assets/images/property-dev/sceneca/sceneca_6.png"),
            require("@/assets/images/property-dev/sceneca/sceneca_7.png"),
            require("@/assets/images/property-dev/sceneca/sceneca_8.png"),
            // require("@/assets/images/property-dev/sceneca/sceneca_9.png"),
            // require("@/assets/images/property-dev/sceneca/sceneca_10.png")
          ]
        },
        {
          id: 'One Bernam',
          name: 'One Bernam', 
          location: '新加坡', 
          desc: "Managed by HY-MCC (Bernam) Pte Ltd with China Jingye Construction Engineering (S) Pte Ltd as the main contractor, this mixed-development project has a project size of 3,846 square metres, a gross floor area of 28,001 square metres and a plot ratio of 7.28. The 99-year leasehold project with a contract sum of S$107 million is a 35-storey development comprising two-storey commercial, one-storey service apartments, 32-storey residential flats with a total of 350 units, a swimming pool, communal facilities, and a multi-storey car park. Located at the heart of the Central Business District (CBD), One Bernam is served by the Tanjong Pagar MRT Station and Prince Edward Road MRT Station, as well as the Ayer Rajah Expressway (AYE). Within reach are Cantonment Primary School and the National Library, while VivoCity is just a short drive away. One Bernam also enjoys proximity to Marina Bay Sands and Great World City. The project commenced work in 2019 with completion slated for 2023.",
          images: [
            // require('@/assets/images/property-dev/one-bernam/1.JPG'), 
            require('@/assets/images/property-dev/one-bernam/ClubhouseNite-6K-Fn4.gif'),
            require('@/assets/images/property-dev/one-bernam/L34_FunctionRoom-6K-3.gif'),
            require('@/assets/images/property-dev/one-bernam/2.gif'), 
            require('@/assets/images/property-dev/one-bernam/Aerial02-6K-ExtFn1.gif'),
            require('@/assets/images/property-dev/one-bernam/ClubhouseINTDay-6K-Fn3.gif'),
            require('@/assets/images/property-dev/one-bernam/Hammock-8K-Fn2.gif'),
            require('@/assets/images/property-dev/one-bernam/Hero02-6K-Fn2.gif'),
            require('@/assets/images/property-dev/one-bernam/Pool1-8k-Fn5.gif'),
           /*  require('@/assets/images/property-dev/one-bernam/PoolCabana-8K-Fn4.jpg'),
            require('@/assets/images/property-dev/one-bernam/Retail-6K-Fn1.jpg'),
            require('@/assets/images/property-dev/one-bernam/Spa-8K-Fn3.jpg'),
            require('@/assets/images/property-dev/one-bernam/Tatami-8k-Fn3.jpg'), */
          ]
        },
        {
          id: 'Provence Residence',
          name: 'Provence Residence', 
          location: '新加坡', 
          desc: 'Provence Residence is inspired by the effortless sophistication and elegance of French design. Various shopping malls like the Sun Plaza and Sembawang Shopping Centre also offer convenient options for lifestyle, dining and entertainment. Provence Residence commenced work in 2020 with completion slated for 2026.',
          images: [
            /* require('@/assets/images/property-dev/provence-residence/6Z2A0107.JPG'), 
            require('@/assets/images/property-dev/provence-residence/6Z2A0131.JPG'),
            require('@/assets/images/property-dev/provence-residence/6Z2A0148.JPG'), */
            require('@/assets/images/property-dev/provence-residence/PR6.gif'),
            require('@/assets/images/property-dev/provence-residence/PR1.gif'),
            require('@/assets/images/property-dev/provence-residence/PR2.gif'), 
            require('@/assets/images/property-dev/provence-residence/PR3.gif'),
            require('@/assets/images/property-dev/provence-residence/PR4.gif'),
            require('@/assets/images/property-dev/provence-residence/PR5.gif'),
          ]
        },
        {
          id: 'Landmark',
          name: 'Landmark', 
          location: '新加坡', 
          desc: 'Located along Chin Swee Road, The Landmark enjoys proximity to the Outram Park MRT Station and Chinatown MRT Station. Within walking distance is the Robertson Quay, and River Valley Primary School is also a short drive away',
          images: [
            require('@/assets/images/property-dev/landmark/7.gif'),
            require('@/assets/images/property-dev/landmark/6.gif'), 
            // require('@/assets/images/property-dev/landmark/11.gif'),
            require('@/assets/images/property-dev/landmark/1.gif'), 
            require('@/assets/images/property-dev/landmark/2.gif'),
            require('@/assets/images/property-dev/landmark/4.gif'),
            require('@/assets/images/property-dev/landmark/5.gif'),
            require('@/assets/images/property-dev/landmark/8.gif'),
            require('@/assets/images/property-dev/landmark/9.gif'),
            require('@/assets/images/property-dev/landmark/10.gif'), 
          ]
        },
      ]
    },
    {
      title: '热销项目',
      subtitle: '东南亚',
      projects: [
        {
          id: 'Sky Villa',
          name: 'Sky Villa',
          location: '柬埔寨', 
          desc: 'The project has a total construction area of 120,000 square metres. Sky Villa comprises two 35-storey towers of luxury residential apartments in the prime district of central Phnom Penh. The property features 256 units ideal for personal or multi-generational family living with generous residential spaces that range from 272 square metres to 509 square metres. ', 
          images: [
            require('@/assets/images/property-dev/sky-vila/1.jpg'),
            require('@/assets/images/property-dev/sky-vila/2.jpg'),
            require('@/assets/images/property-dev/sky-vila/3.jpg'),
            require('@/assets/images/property-dev/sky-vila/4.jpg'),
          ]
        },
        {
          id: 'JKT Living Star',
          name: 'JKT Living Star', 
          location: '雅加达', 
          desc: 'The core location of the Southeast Jakarta area with urban facilities and convenient living. JKT Living Star also enjoys proximity to the upcoming Jakarta Light Rail Transit (LRT) as well as the Ciracas and Cibubur Station. The project commenced work in 2019 and was completed in 2021.', 
          images: [
            require('@/assets/images/property-dev/living-star/6.gif'),
            require('@/assets/images/property-dev/living-star/1.gif'),
            require('@/assets/images/property-dev/living-star/2.gif'),
            // require('@/assets/images/property-dev/living-star/3.jpg'),
            require('@/assets/images/property-dev/living-star/4.gif'),
            require('@/assets/images/property-dev/living-star/5.gif'),
            // require('@/assets/images/property-dev/living-star/7.jpg'),
            require('@/assets/images/property-dev/living-star/8.gif'),
            // require('@/assets/images/property-dev/living-star/9.jpg'),
            require('@/assets/images/property-dev/living-star/10.gif'),
          ]
        },
      ]
    },
  ]
}