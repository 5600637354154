<template>
  <div class="container-property-dev" v-iScroll>
    <gap :height="86.07" />

    <div class="row-instruction flex-row">
      <Instruction class="left" @show-all="showAllProject"/>
      <gap :width="55.87" />
      <Awards class="flex-1"/>
    </div>

    <gap :height="7" />

    <!-- On sale Project -->
    <div ref="titleOfSectionOne">
      <template v-for="(item, i) in langPackage.onSalesProjects">
        <OnSaleProjects :key="`${item.title}${i}`" :list="item" />
      </template>
    </div>

    <gap :height="77" />

    <!-- Other Project -->
    <div class="container-other-project">
      <Projects :data="langPackage.otherProjects" />

      <Projects :data="langPackage.projectMag" :showSubtitle="true" />
    </div>
  </div>
</template>
<script>
import Awards from "./components/Awards.vue";
import Instruction from "./components/Instruction";
import Projects from "./components/Projects";
import OnSaleProjects from "./components/OnSaleProjects.vue";
import jsonEn from './json/json-en'
import jsonZh from './json/json-zh'

export default {
  components: {Awards, Instruction, Projects, OnSaleProjects},
  data() {
    return {
      langPackage: '',
    }
  },
  computed: {
    lang() {
      return this.$store.state.lang;
    }
  },
  watch: {
    lang(newVal) {
      this.langPackage = newVal === 'en' ? jsonEn : jsonZh;
    }
  },
  created() {
    this.langPackage = this.lang === 'en' ? jsonEn : jsonZh;
  },
  methods: {
    showAllProject() {
      const $ = window.$;
      const el = this.$refs.titleOfSectionOne;
      const h = $(el).offset().top;

      $("body,html").animate(
        { scrollTop: h - 40 },
        200
      );
    }
  }
}
</script>
<style type="text/css" lang="scss">
  .container-property-dev {
    padding: 0 10rem;
    padding-bottom: 5.5rem;

    .row-instruction {
      .left { width: 59%; }
    }
  }
</style>